<script>
import Confirmation from '@/components/Confirmation.vue';
import { geneticPatternsDataType, categoriesAnimalDataType, categoriesComplementaryDataType } from '@/utils/enums';
import gql from 'graphql-tag';
import orderBy from 'lodash/orderBy';
import Swal from 'sweetalert2';
import draggable from 'vuedraggable';
import { mapGetters } from 'vuex';

import CreateModule from './CreateModule.vue';

export default {
  components: {
    Confirmation,
    CreateModule,
    draggable,
  },

  data() {
    return {
      loadingFarms: false,
      loadingBatches: false,
      showModules: false,
      form: {},
      farms: [],
      batches: [],
      draggableCards: true,
      batchesDesactivated: [],
      lastFarm: localStorage.getItem('lastFarm'),
      farmNumberHeads: 0,
      farmArea: 0,
      farmAverageWeight: 0,
      farmCapacity: 0,
      geneticPatterns: geneticPatternsDataType,
      categoriesAnimal: categoriesAnimalDataType,
      categoriesComplementary: categoriesComplementaryDataType,
    };
  },

  computed: {
    ...mapGetters('auth', ['isManager']),
  },

  async created() {
    await this.fetchFarms();
    if (this.farms.length) {
      this.lastFarm = localStorage.getItem('lastFarm');
      this.$set(this.form, 'farmId', this.lastFarm);
      await this.fetchBatches();
      await this.calculateFarmInfo();
    }
  },

  methods: {
    async fetchFarms() {
      this.loadingFarms = true;
      try {
        let result;
        if (this.isManager) {
          result = await this.$apollo.query({
            query: gql`
              query farms {
                farms(where: { desactivated: { _is_null: true } }, order_by: [{ name: asc }]) {
                  id
                  name
                  desactivated
                  customer {
                    id
                    name
                  }
                }
              }
            `,
            fetchPolicy: 'no-cache',
          });
          let desactivatedFarms = await this.$apollo.query({
            query: gql`
              query farms {
                farms(where: { desactivated: { _eq: true } }, order_by: [{ name: asc }]) {
                  id
                  name
                  desactivated
                  customer {
                    id
                    name
                  }
                }
              }
            `,
            fetchPolicy: 'no-cache',
          });
          if (desactivatedFarms && desactivatedFarms.data && desactivatedFarms.data.farms) {
            result.data.farms = [...result.data.farms, ...desactivatedFarms.data.farms];
          }
        } else {
          result = await this.$apollo.query({
            query: gql`
              query farms {
                farms(order_by: [{ name: asc }]) {
                  id
                  name
                  customer {
                    id
                    name
                  }
                }
              }
            `,
            fetchPolicy: 'no-cache',
          });
        }
        this.loadingFarms = false;
        this.farms = result.data.farms.map((item) => ({ value: item.id, text: item.name, ...item }));
        if (this.farms.length > 0) {
          this.form.farmId = this.farms[0].id;
          await this.fetchBatches();
        }
      } catch (e) {
        console.error(e);
      }
      this.loadingFarms = false;
    },

    farmFilter(item, queryText) {
      const textOne = item?.name?.toLowerCase();
      const textTwo = item?.customer?.name?.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
    },

    async fetchBatches() {
      this.loadingBatches = true;
      try {
        if (this.form.farmId) {
          const result = await this.$apollo.query({
            query: gql`
              query batches($farmId: uuid!) {
                batches(
                  where: { module: { farm_id: { _eq: $farmId } }, status: { _eq: "ATIVO" } }
                  order_by: [{ status: asc, module: { code: asc }, code: asc }]
                ) {
                  id
                  code
                  name
                  status
                  order
                  module {
                    code
                    name
                    paddocks_aggregate {
                      aggregate {
                        count
                        sum {
                          area
                        }
                      }
                    }
                  }
                  genetic_pattern
                  category_animal
                  category_complementary
                  number_of_heads
                  average_weight
                  expected_performance
                  day_occupation
                }
              }
            `,
            variables: {
              farmId: this.form.farmId,
            },
            fetchPolicy: 'no-cache',
          });
          this.batches = result.data.batches;
          if (this.batches && this.batches.length > 0) {
            this.batches.forEach(async batch => {
              const transaction = await this.$apollo.query({
                query: gql`
                  query latestBatchTransaction($batchId: uuid!) {
                    batch_transactions(
                      where: { batch_id: { _eq: $batchId } }
                      order_by: { created_at: desc } # Ordenar pela mais recente
                      limit: 1 # Apenas a última transação
                    ) {
                      instant_charge
                      module_capacity
                    }
                  }
                `,
                variables: {
                  batchId: batch.id,
                },
                fetchPolicy: 'no-cache',
              });
              if (transaction && transaction.data.batch_transactions.length > 0) {
                batch.instant_charge = transaction.data.batch_transactions[0].instant_charge
                batch.capacity = transaction.data.batch_transactions[0].module_capacity
              }
            })
          }
          this.batches = orderBy(this.batches, 'order', 'asc');
          const desactivated = await this.$apollo.query({
            query: gql`
              query batches($farmId: uuid!) {
                batches(
                  where: { module: { farm_id: { _eq: $farmId } }, status: { _eq: "ENCERRADO" } }
                  order_by: [{ status: asc, module: { code: asc }, code: asc }]
                ) {
                  id
                  code
                  name
                  status
                  order
                  module {
                    code
                    name
                    paddocks_aggregate {
                      aggregate {
                        count
                        sum {
                          area
                        }
                      }
                    }
                  }
                  genetic_pattern
                  category_animal
                  category_complementary
                  number_of_heads
                  average_weight
                  expected_performance
                }
              }
            `,
            variables: {
              farmId: this.form.farmId,
            },
            fetchPolicy: 'no-cache',
          });
          this.batchesDesactivated = desactivated.data.batches;
          this.batchesDesactivated = orderBy(this.batchesDesactivated, 'order', 'asc');
        }
      } catch (e) {
        console.error(e);
      }
      this.loadingBatches = false;
    },

    async modulesArea() {
      try {
        if (this.form.farmId) {
          const result = await this.$apollo.query({
            query: gql`
              query modules($farmId: uuid!) {
                modules(where: { farm_id: { _eq: $farmId } }, order_by: [{ code: asc }]) {
                  paddocks_aggregate {
                    aggregate {
                      count
                      sum {
                        area
                      }
                    }
                  }
                }
              }
            `,
            variables: {
              farmId: this.form.farmId,
            },
            fetchPolicy: 'no-cache',
          });
          return result.data.modules;
        }
      } catch (e) {
        console.error(e);
      }
    },

    async handleChangeFarm() {
      await this.fetchBatches();
      if (this.farms.length) this.lastFarm = localStorage.setItem('lastFarm', this.form.farmId);

      this.calculateFarmInfo();
    },

    remove(id) {
      Swal.fire({
        title: 'Deseja excluir o lote?',
        text: 'Você não poderá reverter isso!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Sim, apague!',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.value) {
          return this.removeBatch(id);
        }
      });
    },

    async removeBatch(id) {
      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($id: uuid!, $date: timestamptz) {
              update_batches_by_pk(pk_columns: { id: $id }, _set: { deleted_at: $date }) {
                id
              }
              update_batch_transactions(where: { batch_id: { _eq: $id } }, _set: { deleted_at: $date }) {
                affected_rows
              }
            }
          `,
          variables: {
            id,
            date: new Date(),
          },
        });
        Swal.fire({
          icon: 'success',
          text: 'Removido com sucesso!',
          position: 'top-end',
          toast: true,
          showConfirmButton: false,
          timer: 3000,
        });
        this.fetchBatches();
      } catch (e) {
        console.error(e);
      }
    },

    srcBatchImage(batch) {
      try {
        if (batch.genetic_pattern) {
          return require(`@/assets/animals/${batch.genetic_pattern}/${batch.category_animal}.jpeg`);
        }
      } catch (e) {
        return require(`@/assets/animals/${batch.genetic_pattern}.jpeg`);
      }
    },

    async calculateFarmInfo() {
      this.farmNumberHeads = 0;
      this.farmArea = 0;
      this.farmAverageWeight = 0;
      this.farmCapacity = 0;
      for (let index = 0; index < this.batches.length; index++) {
        this.farmNumberHeads = this.batches[index].number_of_heads + this.farmNumberHeads;
        this.farmAverageWeight =
          this.batches[index].average_weight * this.batches[index].number_of_heads + this.farmAverageWeight;
      }
      const modules = await this.modulesArea();
      for (let index = 0; index < modules.length; index++) {
        this.farmArea = modules[index].paddocks_aggregate.aggregate.sum.area + this.farmArea;
      }
      this.farmCapacity = this.farmAverageWeight / 450 / this.farmArea;
      this.farmAverageWeight = this.farmAverageWeight / this.farmNumberHeads;
    },

    async saveOrder(batches) {
      try {
        for (let index = 0; index < batches.length; index++) {
          let form = {
            order: index,
          };
          await this.$apollo.mutate({
            mutation: gql`
              mutation ($id: uuid!, $batch: batches_set_input!) {
                update_batches_by_pk(pk_columns: { id: $id }, _set: $batch) {
                  id
                }
              }
            `,
            variables: {
              id: batches[index].id,
              batch: form,
            },
          });
        }
      } catch (e) {
        console.error(e);
      }
    },

    checkDayOccupation(batch) {
      if (batch.day_occupation >= 1) {
        return batch.day_occupation + ' d';
      } else {
        return (batch.day_occupation * 24).toFixed(0) + ' h';
      }
    },

    checkRestTime(batch) {
      if (batch && batch.module && batch.module.paddocks_aggregate) {
        return (
          (batch.day_occupation * batch.module.paddocks_aggregate.aggregate.count - batch.day_occupation).toFixed(0) +
          ' d'
        );
      }
    },
  },
};
</script>

<template>
  <div>
    <v-card>
      <create-module v-show="showModules" :dialog="showModules" @closeDialog="showModules = false"></create-module>
      <v-card-text>
        <v-row>
          <v-col md="4">
            <v-autocomplete
              v-model="form.farmId"
              :items="farms"
              :loading="loadingFarms"
              :filter="farmFilter"
              label="Fazenda"
              dense
              outlined
              hide-details
              persistent-placeholder
              @input="handleChangeFarm"
            >
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title v-if="!data.item.desactivated" v-html="data.item.name"></v-list-item-title>
                  <v-list-item-title
                    v-if="data.item.desactivated"
                    v-html="data.item.name + ' (inativa)'"
                  ></v-list-item-title>
                  <v-list-item-subtitle v-html="data.item.customer.name"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col md="4">
            <v-btn color="primary" dark :to="`/lotes/novo?farmId=${form.farmId}`"> Criar novo lote </v-btn>
            <v-btn color="primary" class="ml-2" dark @click="showModules = !showModules"> Módulos </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="3">
            <v-card color="success darken-2" dark>
              <v-card-title class="subtitle-2">Rebanho</v-card-title>
              <v-card-text>
                <div class="py-1">
                  <h1 class="white--text">{{ farmNumberHeads }} cabeças</h1>
                  <v-avatar class="icon-bottom-card">
                    <v-icon>mdi-cow</v-icon>
                  </v-avatar>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col sm="3">
            <v-card color="success darken-2" dark>
              <v-card-title class="subtitle-2">Área</v-card-title>
              <v-card-text>
                <div class="py-1">
                  <h1 class="white--text">{{ farmArea | number:decimals(1) }} ha</h1>
                  <v-avatar class="icon-bottom-card">
                    <v-icon>mdi-map</v-icon>
                  </v-avatar>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col sm="3">
            <v-card color="success darken-1" dark>
              <v-card-title class="subtitle-2">Peso médio</v-card-title>
              <v-card-text>
                <div class="py-1">
                  <h1 class="white--text">{{ farmAverageWeight? farmAverageWeight : 0 | number:decimals(1) }} kg</h1>
                  <v-avatar class="icon-bottom-card">
                    <v-icon>mdi-abacus</v-icon>
                  </v-avatar>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col sm="3">
            <v-card color="success" dark>
              <v-card-title class="subtitle-2">Lotação</v-card-title>
              <v-card-text>
                <div class="py-1">
                  <h1 class="white--text">{{ farmCapacity | number:decimals(2) }} UA/ha</h1>
                  <v-avatar class="icon-bottom-card">
                    <v-icon>mdi-texture-box</v-icon>
                  </v-avatar>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-card-title v-show="batches.length" style="color: black"> Lotes Ativos</v-card-title>
        <draggable
          v-show="batches.length"
          v-model="batches"
          class="row"
          :sort="true"
          handle=".btnDrag"
          @end="saveOrder(batches)"
        >
          <v-col v-for="(batch, index) in batches" :key="batch.id" md="4" sm="6" xs="12">
            <v-card :to="`/lotes/${batch.id}`">
              <v-img
                :src="srcBatchImage(batch)"
                class="imgClass"
                gradient="to bottom, rgba(0,0,0,.0), rgba(0,0,0,.5)"
                height="230px"
              >
                <v-layout row wrap align-center justify-center>
                  <v-flex xs12>
                    <v-card-title>
                      <div class="flex xs12">
                        <div v-if="batch.status === 'ENCERRADO'" class="display-1 brown darken-4 text-center mt-3">
                          LOTE ENCERRADO
                        </div>
                      </div>
                    </v-card-title>
                  </v-flex>
                </v-layout>
              </v-img>
              <v-card-actions>
                <div class="text-center">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip v-bind="attrs" class="mx-1 mb-1" color="green darken-4" outlined v-on="on">
                        <v-icon left> mdi-cow </v-icon>
                        {{ batch.number_of_heads }}
                      </v-chip>
                    </template>
                    <span>Quantidade de animais (cabeças)</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip v-bind="attrs" class="mx-1 mb-1" color="green darken-4" outlined v-on="on">
                        <v-icon left> mdi-weight-kilogram </v-icon>
                        {{ batch.average_weight | number:decimals(1) }}
                      </v-chip>
                    </template>
                    <span>Peso médio do lote (kg)</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip v-bind="attrs" class="mx-1 mb-1" color="green darken-4" outlined v-on="on">
                        <v-icon left> mdi-poll </v-icon>
                        {{ batch.expected_performance | number:decimals(0) || 0 }}
                      </v-chip>
                    </template>
                    <span>Ganho de peso (g/cab/dia)</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip v-bind="attrs" class="mx-1 mb-1" color="green darken-4" outlined v-on="on">
                        <v-icon left> mdi-calendar-clock </v-icon>
                        {{ checkDayOccupation(batch) }}
                      </v-chip>
                    </template>
                    <span>Tempo de Ocupação</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip v-bind="attrs" class="mx-1 mb-1" color="green darken-4" outlined v-on="on">
                        <v-icon left> mdi-calendar-range </v-icon>
                        {{ checkRestTime(batch) }}
                      </v-chip>
                    </template>
                    <span>Tempo de Descanso</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip v-bind="attrs" class="mx-1 mb-1" color="green darken-4" outlined v-on="on">
                        <span class="d-inline-flex align-center">
                          <v-icon left class="mr-n2">mdi-alpha-u</v-icon>
                          <v-icon left class="mr-n1">mdi-alpha-a</v-icon>
                        </span>
                        {{ batch.capacity | number:decimals(2) }}
                      </v-chip>
                    </template>
                    <span>Lotação do lote (UA)</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip v-bind="attrs" class="mx-1 mb-1" color="green darken-4" outlined v-on="on">
                        <v-icon left> mdi-land-plots-circle </v-icon>
                        {{ batch.instant_charge | number:decimals(1) }}
                      </v-chip>
                    </template>
                    <span>Carga instantânea</span>
                  </v-tooltip>
                  <v-tooltip bottom></v-tooltip>
                </div>
                <v-spacer></v-spacer>
                <v-btn icon class="btnDrag">
                  <v-icon small>mdi-arrow-all</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn icon :to="`/lotes/${batch.id}/editar`">
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
                <v-btn v-if="isManager" icon @click.stop.prevent="remove(batch.id)">
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </v-card-actions>
              <v-card-title>
                <div>{{ batch.module.name }}</div>
              </v-card-title>
              <v-card-subtitle class="pb-5 text-subtitle-2">
                {{ geneticPatterns[batch.genetic_pattern] }}
                {{ categoriesAnimal[batch.category_animal] ? '- ' + categoriesAnimal[batch.category_animal] : '' }}
                {{
                  categoriesComplementary[batch.category_complementary]
                    ? '- ' + categoriesComplementary[batch.category_complementary]
                    : ''
                }}
              </v-card-subtitle>
              <v-card-text class="text-body-1"> Lote {{ batch.code }} - {{ batch.name }} </v-card-text>
            </v-card>
          </v-col>
        </draggable>

        <v-card-title v-show="batchesDesactivated.length" style="color: black"> Lotes Encerrados</v-card-title>
        <draggable
          v-show="batchesDesactivated.length"
          v-model="batchesDesactivated"
          class="row"
          :sort="true"
          handle=".imgClass"
          @end="saveOrder(batchesDesactivated)"
        >
          <v-col v-for="(batch, index) in batchesDesactivated" :key="batch.id" md="4" sm="6" xs="12">
            <v-card :to="`/lotes/${batch.id}`">
              <v-img
                :src="srcBatchImage(batch)"
                class="imgClass"
                gradient="to bottom, rgba(0,0,0,.0), rgba(0,0,0,.5)"
                height="230px"
              >
                <v-layout row wrap align-center justify-center>
                  <v-flex xs12>
                    <v-card-title>
                      <div class="flex xs12">
                        <div v-if="batch.status === 'ENCERRADO'" class="display-1 brown darken-4 text-center mt-3">
                          LOTE ENCERRADO
                        </div>
                      </div>
                    </v-card-title>
                  </v-flex>
                </v-layout>
              </v-img>
              <v-card-actions>
                <div class="text-center">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip v-bind="attrs" class="mx-1 mb-1" color="green darken-4" outlined v-on="on">
                        <v-icon left> mdi-cow </v-icon>
                        {{ batch.number_of_heads }}
                      </v-chip>
                    </template>
                    <span>Quantidade de animais (cabeças)</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip v-bind="attrs" class="mx-1 mb-1" color="green darken-4" outlined v-on="on">
                        <v-icon left> mdi-weight-kilogram </v-icon>
                        {{ batch.average_weight | number:decimals(1) }}
                      </v-chip>
                    </template>
                    <span>Peso médio do lote (kg)</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip v-bind="attrs" class="mx-1 mb-1" color="green darken-4" outlined v-on="on">
                        <v-icon left> mdi-poll </v-icon>
                        {{ batch.expected_performance || 0 }}
                      </v-chip>
                    </template>
                    <span>Ganho de peso (g/cab/dia)</span>
                  </v-tooltip>
                </div>
                <v-spacer></v-spacer>
                <v-btn icon class="btnDrag" v-on="on">
                  <v-icon small>mdi-arrow-all</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn icon :to="`/lotes/${batch.id}/editar`">
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
                <v-btn v-if="isManager" icon @click.stop.prevent="remove(batch.id)">
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </v-card-actions>
              <v-card-title>
                <div>{{ batch.module.name }}</div>
              </v-card-title>
              <v-card-subtitle class="pb-5 text-subtitle-2">
                {{ geneticPatterns[batch.genetic_pattern] }}
                {{ categoriesAnimal[batch.category_animal] ? '- ' + categoriesAnimal[batch.category_animal] : '' }}
                {{
                  categoriesComplementary[batch.category_complementary]
                    ? '- ' + categoriesComplementary[batch.category_complementary]
                    : ''
                }}
              </v-card-subtitle>
              <v-card-text class="text-body-1"> Lote {{ batch.code }} - {{ batch.name }} </v-card-text>
            </v-card>
          </v-col>
        </draggable>
        <v-row v-show="!batches.length">
          <v-col md="12">
            <v-card>
              <v-card-text>
                <v-alert :value="true"> Nenhum lote cadastrado. </v-alert>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<style scoped>
.icon-bottom-card {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 2.5rem;
}
.imgClass {
  align-content: top;
  color: white;
}
</style>
